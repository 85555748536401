import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import FilerobotImageEditor, { TABS, TOOLS } from 'react-filerobot-image-editor';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Stack, Typography, Button, Fab, Tooltip, Box } from '@mui/material';
import "../components/dialog/DialogUpload.css"

import { useAuth } from '../context/AuthContext';
// ----------------------------------------------------------------------

export default function ProductsPage() {
  const { user } = useAuth();

  const [sourceImage, setSourceImage] = useState(null);
  const [showEditor, setShowEditor] = useState(false);
  const [loading, setLoading] = useState(false);

  const [uploadImage, setUploadImage] = useState('');
  const [uploadStatus, setUploadStatus] = useState("select");

  const [uploadLoading, setUploadLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadImage(file.className)
      setLoading(true);
      setUploadLoading(true);
      const formData = new FormData();
      formData.append('file', file);

      try {
        setUploadStatus("uploading");
        // Make the POST request to the API
        const response = await axios.post('https://viume-papsmear-ml-bjh4uafqtq-tl.a.run.app/predict', 
        formData,{
          onUploadProgress : (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          }
        },{
          responseType: 'blob',  // Request response as a binary blob
        }, );
        // Create a Blob URL for the image response
        const blobUrl = URL.createObjectURL(response.data);
        // Set the source image to the Blob URL
        setSourceImage(blobUrl);
        setShowEditor(true); // Show the image editor
        setLoading(false);
        setUploadLoading(false);
        setUploadStatus("uploading");
      } catch (error) {
        console.error('Error uploading file:', error);
        alert('Error uploading file. Please try again later.');
        setShowEditor(false); // Hide the image editor
        setUploadLoading(false);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title> Prediction </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Single Image Prediction
        </Typography>

        <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
          {/* Input element to select a file */}
          <label htmlFor="fileInput">
            <input
              type="file"
              accept="image/*"
              onChange={handleFileInputChange}
              style={{ display: 'none' }}
              id="fileInput"
            />
            <Button variant="contained" component="span">
              Upload and Predict
            </Button>
          </label>
        </Stack>

        <Box
          sx={{
            width: '100%',
            height: '600px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {uploadLoading && <Container maxWidth="xl" >
            <div className="file-info">
              <div style={{ flex: 1 }}>
              <Typography variant="caption" sx={{ margin: '0 auto', display: 'block' }}>
                "uploading..."
              </Typography>

                <div className="progress-bg">
                  <div className="progress" style={{ width: `${progress}%` }} />
              </div>
              </div>

              <div className="check-circle">
                  {uploadStatus === "uploading" ? (
                    `${progress}%`
                  ) : uploadStatus === "done" ? (
                    <span
                      className="material-symbols-outlined"
                      style={{ fontSize: "20px" }}
                    >
                      check
                    </span>
                  ) : null}
                </div>
            </div>
            </Container>}
          {showEditor && sourceImage && (
            <FilerobotImageEditor
              source={sourceImage}
              onSave={(editedImageObject, designState) => console.log('saved', editedImageObject, designState)}
              annotationsCommon={{
                fill: '#ff0000',
              }}
              Text={{ text: 'VIUME' }}
              Rotate={{ angle: 90, componentType: 'slider' }}
              tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.RESIZE, TABS.FILTERS, TABS.FINETUNE]}
              defaultTabId={TABS.RESIZE}
              defaultToolId={TOOLS.PEN}
            />
          )}
        </Box>
      </Container>
    </>
  );
}
