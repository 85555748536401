import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import "../components/dialog/DialogUpload.css";

// @mui
import CircularProgress from '@mui/material/CircularProgress';
import FaceIcon from '@mui/icons-material/Face';

import { Container, Stack, Typography, Button, Box, Chip, Fab } from '@mui/material';

// components
import DialogUpload from '../components/dialog/DialogUpload';
import DialogEditImage from '../components/dialog/DialogEditImage';
import { useAuth } from '../context/AuthContext';
import { FolderList } from '../sections/@dashboard/folder';

// mock
import WORKBOOK from '../_mock/workbook';

// ----------------------------------------------------------------------

export default function WorkbookPage() {
  const params = useParams();
  const [workspace, setWorkspace] = useState({});
  const [loading, setLoading] = useState(false);
  const [forbidden, setForbidden] = useState(false);
  const { user } = useAuth();
  const [workbook, setWorkbook] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  
  const [updated, setUpdated] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  
  const [uploadImage, setUploadImage] = useState('');
  const [progress, setProgress] = useState(0);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("select");
  const [fileSize, setFileSize] = useState(0); // State for file size

  const [isEditImageOpen, setIsEditImageOpen] = useState(false);

  const handleOpenEditImageDialog = () => {
    setIsEditImageOpen(true);
  };

  const handleCloseEditImageDialog = () => {
    setCurrentImage(null);
    setIsEditImageOpen(false);
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleDelete = (paramnya) => {
    setUpdated(!updated);
  };

  const handleEditDialog = (id, name, collaborator, isPublic, zoomLevel) => {
    try {
      axios
        .put(
          `http://viume-api.yarsi.ai/image`,
          { id, name, collaborator, isPublic, zoomLevel },
          { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
        )
        .then((response) => {
          setUpdated(!updated);
        });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleEdit = (data) => {
    setCurrentImage(data);
    console.log(data);
    handleOpenEditImageDialog();
  };

  const handleUpload = async (name, imageFile, collaborator, isPublic, zoomLevel) => {
    setUploadImage(imageFile.name);

    // Set file size in state
    setFileSize(imageFile.size);

    setUploadLoading(true);

    try {
      setUploadStatus("uploading");

      const formData = new FormData();
      formData.append('file', imageFile);
      formData.append('collaborator', collaborator);
      formData.append('workspace_id', params.id);
      formData.append('name', name);
      formData.append('isPublic', isPublic);
      formData.append('zoomLevel', zoomLevel);

      await axios.post(
        'http://viume-api.yarsi.ai/image',
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          }
        }
      ).then((response) => {
        setUpdated(!updated);
        setUploadLoading(false);
        setUploadStatus("done");
        setProgress(0);
      }).catch((error) => {
        console.error('Error:', error);
        setUploadLoading(false);
      });

    } catch (error) {
      
      setUploadLoading(false);
    }
    setUploadLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    try {
      const headers = {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      };

      axios
        .get(`http://viume-api.yarsi.ai/workspace/${params.id}`, headers)
        .then((response) => {
          setWorkspace(response.data.workspace);
          setForbidden(false);

          const workbookId = response.data.workspace._id;

          axios
            .get(`http://viume-api.yarsi.ai/workbook/${workbookId}`, headers)
            .then((response) => {
              setWorkbook(response.data.workbook);
              setLoading(false);
            })
            .catch((error) => {
              console.error('Error fetching workbook data:', error);
              setLoading(false);
              setForbidden(true);
            });
        })
        .catch((error) => {
          console.error('Error fetching workspace data:', error);
          setLoading(false);
          setForbidden(true);
        });
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      setForbidden(true);
    }
  }, [updated]);


  if (uploadLoading) {
    return (
      <Container maxWidth="xl">
        <div className="file-info">
          <div style={{ flex: 1 }}>
            <Typography variant="caption" sx={{ margin: '0 auto', display: 'block' }}>
              "{uploadImage}" ({(fileSize / (1024*1024)).toFixed(2)} MB)
              <CircularProgress />
            </Typography>
            <div className="progress-bg">
              <div className="progress" style={{ width: `${progress}%` }} />
            </div>
          </div>
          <div className="check-circle">
            {uploadStatus === "uploading" ? (
              `${progress}%`
            ) : uploadStatus === "done" ? (
              <span className="material-symbols-outlined" style={{ fontSize: "20px" }}>
                check
              </span>
            ) : null}
          </div>
        </div>
      </Container>
    );
  }

  if (loading) {
    return (
      <Container maxWidth="xl">
        <Box textAlign="center" py={5}>
          <CircularProgress />
          <Typography variant="caption" sx={{ margin: '0 auto', display: 'block' }}>
            ...
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title> Workbook </title>
      </Helmet>
      <Container maxWidth="xl">
        {forbidden ? (
          <Box textAlign="center" py={5}>
            <img
              src={'/assets/illustrations/workspace_forbidden.png'}
              alt="No Data"
              style={{ maxWidth: '100%', margin: '0 auto' }}
            />
          </Box>
        ) : (
          !uploadLoading && (
            <>
              <Typography variant="h4">{`${user.username} > ${workspace.name}`}</Typography>
              {workspace.description && (
                <Typography variant="body2" sx={{ mb: 1 }}>
                  {workspace.description}
                </Typography>
              )}
              <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-start">
                <Stack direction="row" spacing={1} flexShrink={0}>
                  <Chip icon={<FaceIcon />} label={workspace.email} variant="outlined" />
                </Stack>
              </Stack>

              <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end">
                {uploadLoading && (
                  <Stack direction="row" spacing={1} flexShrink={0} sx={{ mr: 2 }}>
                    <Fab variant="extended">
                      <CircularProgress sx={{ mr: 1 }} />
                      Uploading
                    </Fab>
                  </Stack>
                )}
                <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
                  <Button variant="contained" onClick={handleOpenDialog}>
                    Add File
                  </Button>
                </Stack>
              </Stack>

              {workbook && workbook.length > 0 ? (
                <FolderList items={workbook} onDelete={handleDelete} onEdit={handleEdit} />
              ) : (
                <Box textAlign="center" py={5}>
                  <img
                    src={'/assets/illustrations/workbook_kosong.png'}
                    alt="No Data"
                    style={{ maxWidth: '100%', margin: '0 auto' }}
                  />
                </Box>
              )}
              <DialogUpload isOpen={isDialogOpen} onClose={handleCloseDialog} onUpload={handleUpload} />
              {currentImage && (
                <DialogEditImage
                  isOpen={isEditImageOpen}
                  onClose={handleCloseEditImageDialog}
                  onUpload={handleEditDialog}
                  currentImage={currentImage}
                />
              )}
            </>
          )
        )}
      </Container>
    </>
  );
}
