// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: icon('ic_analytics'),
  // },
  // {
  //   title: 'user',
  //   path: '/dashboard/user',
  //   icon: icon('ic_user'),
  // },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
  {
    title: 'Home',
    path: '/dashboard/home',
    icon: icon('ic_user'),
    role: 'ALL',
  },
  {
    title: 'Prediction',
    path: '/dashboard/prediction',
    icon: icon('ic_analytics'),
    role: 'ALL'
  },
  {
    title: 'users',
    path: '/dashboard/user',
    icon: icon('ic_user'),
    role: 'ADMIN'
  },

  // {
  //   title: 'Image',
  //   path: '/dashboard/image',
  //   icon: icon('ic_disabled'),
  // },
  // {
  //   title: 'Workbook',
  //   path: '/dashboard/workbook',
  //   icon: icon('ic_disabled'),
  // },
  // {
  //   title: 'Learning',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  //   role: 'ALL'
  // },
  
  // {
  //   title: 'Help',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  //   role: 'ALL'
  // },

  

];

export default navConfig;
