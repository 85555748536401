import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Chip,
  Select,
  MenuItem,
} from '@mui/material';

export default function CreateWorkbookDialog({ isOpen, onClose, onUpload, currentImage }) {
  const [name, setName] = useState(currentImage.name || '');
  const [nameError, setNameError] = useState('');
  const [collaborators, setCollaborators] = useState(currentImage.collaborator || []);
  const [newCollaborator, setNewCollaborator] = useState('');
  const [isPublic, setIsPublic] = useState(currentImage.isPublic || false);
  const [zoomLevel, setZoomLevel] = useState(currentImage.zoomLevel || 1);

  const zoomLevels = [1, 5, 10, 20];

  const handleZoomLevelChange = (event) => {
    setZoomLevel(event.target.value);
  };

  const validateName = (value) => {
    if (!value) {
      setNameError('Name is required');
    } else {
      setNameError('');
    }
  };

  const handleUpload = () => {
    if (!nameError && name) {
      onUpload(currentImage._id, name, collaborators, isPublic, zoomLevel);
      onClose();
    } else {
      setNameError('Name is required');
    }
  };

  const handleAddCollaborator = () => {
    if (newCollaborator.trim() !== '') {
      if (isValidEmail(newCollaborator) && collaborators.length < 3) {
        setCollaborators([...collaborators, newCollaborator.trim()]);
        setNewCollaborator('');
      }
    }
  };

  const handleNewCollaboratorChange = (e) => {
    setNewCollaborator(e.target.value);

    if (e.target.value.trim() !== '' && /\s|,$/.test(e.target.value)) {
      handleAddCollaborator();
    }
  };

  const handleRemoveCollaborator = (index) => {
    const newCollaborators = collaborators.filter((_, i) => i !== index);
    setCollaborators(newCollaborators);
  };
  const handleNewCollaboratorBlur = () => {
    handleAddCollaborator();
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleClose = () => {
    setName('');
    setNameError('');
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Edit Image</DialogTitle>
      <DialogContent>
        <TextField
          name="name"
          label="Name"
          fullWidth
          value={name}
          onChange={(e) => {
            validateName(e.target.value);
            setName(e.target.value);
          }}
          required
          error={Boolean(nameError)}
          helperText={nameError}
          sx={{ mt: 1 }}
        />
        <div>
          {collaborators.map((collaborator, index) => (
            <Chip key={index} label={collaborator} onDelete={() => handleRemoveCollaborator(index)} sx={{ m: 1 }} />
          ))}
        </div>
        <TextField
          sx={{ mt: 2, mb:2 }}
          label="Add Collaborator"
          fullWidth
          value={newCollaborator}
          onChange={handleNewCollaboratorChange}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleAddCollaborator();
            }
          }}
          onBlur={handleNewCollaboratorBlur}
          disabled={collaborators.length >= 3} // Disable the TextField when the limit is reached
        />

        <>
          <Typography variant="subtitle" sx={{ mt: 1}}>
            Image Zoom Level:
          </Typography>
          <Select value={zoomLevel} onChange={handleZoomLevelChange} fullWidth sx={{ mt: 1 }}>
            {zoomLevels.map((level) => (
              <MenuItem key={level} value={level}>
                {`${level}x`}
              </MenuItem>
            ))}
          </Select>
        </>

        <FormControlLabel
          control={
            <Checkbox
              checked={isPublic}
              onChange={(event) => setIsPublic(event.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Public Access"
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleUpload} color="primary">
          Edit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
