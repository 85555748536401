import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Chip, TextField } from '@mui/material';

export default function CreateWorkbookDialog({ isOpen, onClose, onCreate }) {
  const [name, setName] = useState('');
  const [collaborators, setCollaborators] = useState([]);
  const [newCollaborator, setNewCollaborator] = useState('');
  const [description, setDescription] = useState('');
  const [nameError, setNameError] = useState('');


  const validateName = (value) => {
    if (!value) {
      setNameError('Name is required');
    } else {
      setNameError('');
    }
  };

  const handleCreate = () => {
    if(!nameError) {
      onCreate(name, collaborators, description);
      handleClose();
    }
    
  };

  const handleAddCollaborator = () => {
    if (newCollaborator.trim() !== '') {
      if (isValidEmail(newCollaborator) && collaborators.length < 3) {
        setCollaborators([...collaborators, newCollaborator.trim()]);
        setNewCollaborator('');
      }
    }
  };

  const handleNewCollaboratorChange = (e) => {
    setNewCollaborator(e.target.value);

    if (e.target.value.trim() !== '' && /\s|,$/.test(e.target.value)) {
      handleAddCollaborator();
    }
  };

  const handleRemoveCollaborator = (index) => {
    const newCollaborators = collaborators.filter((_, i) => i !== index);
    setCollaborators(newCollaborators);
  };
  const handleNewCollaboratorBlur = () => {
    handleAddCollaborator();
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleClose = () => {
    setName('');
    setNameError('');
    setCollaborators([]);
    setNewCollaborator('');
    setDescription('');
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Create Workbook</DialogTitle>
      <DialogContent>
      <TextField
          name="name"
          label="Name"
          fullWidth
          onChange={(e) => {
            setName(e.target.value);
            validateName(e.target.value);
          }}
          required
          error={Boolean(nameError)}
          helperText={nameError}
          sx={{ mt: 1 }}
        />
        <div>
          {collaborators.map((collaborator, index) => (
            <Chip key={index} label={collaborator} onDelete={() => handleRemoveCollaborator(index)} sx={{ m: 1 }} />
          ))}
        </div>
        <TextField
          sx={{ mt: 2 }}
          label="Add Collaborator"
          fullWidth
          value={newCollaborator}
          onChange={handleNewCollaboratorChange}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleAddCollaborator();
            }
          }}
          onBlur={handleNewCollaboratorBlur}
          disabled={collaborators.length >= 3} // Disable the TextField when the limit is reached
        />
        <TextField
          sx={{ mt: 2 }}
          label="Description"
          multiline
          rows={4}
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleCreate} color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
