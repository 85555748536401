import { Helmet } from 'react-helmet-async';
import React, { useState } from 'react';
// @mui
import { Container, Stack, Typography } from '@mui/material';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ChangePassForm } from '../sections/auth/changepass';

export default function SettingPage() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Settings | ViuMe </title>
      </Helmet>

      <Container maxWidth="md">
        <Typography variant="h4" sx={{ mb: 5 }}>
          User Settings
        </Typography>

        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Profile" value="1" />
                <Tab label="Change Password" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">profile</TabPanel>
            <TabPanel value="2">
                <ChangePassForm/>
            </TabPanel>
          </TabContext>
        </Box>
      </Container>
    </>
  );
}
