import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Stack, IconButton, InputAdornment, TextField, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import Iconify from '../../../components/iconify';
import { useAuth } from '../../../context/AuthContext';

export default function PasswordChangeForm() {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const { changePassword, isAuthenticated } = useAuth();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false)

  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');

  const validateCurrentPassword = (value) => {
    if (!value) {
      setCurrentPasswordError('Current password is required');
    } else {
      setCurrentPasswordError('');
    }
  };

  const validateNewPassword = (value) => {
    if (!value) {
      setNewPasswordError('New password is required');
    } else {
      setNewPasswordError('');
    }
  };

  const handleClick = async () => {
    try {
      setError(false);
      setSuccess(false);
      validateCurrentPassword(currentPassword);
      validateNewPassword(newPassword);
  
      if (!currentPasswordError && !newPasswordError) {
        const dataForm = {
          currentpassword: currentPassword,
          email: user.email,
          newpassword: newPassword,
        };
  
        const response = await axios.post('http://viume-api.yarsi.ai/resetpass', dataForm, {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        });
  
        if (response.data.error) {
          console.log('Error:', response.data.error);
          setError(true);
        } else {
          setSuccess(true);
        }
      }
    } catch (error) {
      console.error('An error occurred:', error.message);
      setError(true);
    }
  
  };
  

  return (
    <>
      {success && <Alert sx={{mb: 3}} severity="success">Password Berhasil diubah</Alert>}
      {error && <Alert sx={{mb: 3}} severity="error">Password Gagal diubah</Alert>}
      <Stack spacing={3}>
        <TextField
          name="currentPassword"
          label="Current Password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => {
            setCurrentPassword(e.target.value);
            validateCurrentPassword(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          required
          error={Boolean(currentPasswordError)}
          helperText={currentPasswordError}
        />

        <TextField
          name="newPassword"
          label="New Password"
          type={showNewPassword ? 'text' : 'password'}
          onChange={(e) => {
            setNewPassword(e.target.value);
            validateNewPassword(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                  <Iconify icon={showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          required
          error={Boolean(newPasswordError)}
          helperText={newPasswordError}
        />
      </Stack>

      <LoadingButton sx={{ my: 2 }} size="large" type="submit" variant="contained" onClick={handleClick}>
        Change Password
      </LoadingButton>
    </>
  );
}
