import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import { Container, Stack, Typography, Button, Fab, Tooltip } from '@mui/material';

import OpenSeaPlain from '../components/deepzoom/OpenSeaDragonFull';
import { useAuth } from '../context/AuthContext';
// ----------------------------------------------------------------------

export default function ProductsPage() {
  const params = useParams();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [forbidden, setForbidden] = useState(false);
  const [workbook, setWorkbook] = useState(null);
  const [workspace, setWorkspace] = useState(null);

  useEffect(() => {
    setLoading(true);
    const headers = {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
    };

    axios
      .get(`http://viume-api.yarsi.ai/image/${params.fileName}`, headers)
      .then((response) => {
        console.log(params.fileName);
        console.log(response.data.workbook);
        setWorkbook(response.data.workbook);

        axios
          .get(`http://viume-api.yarsi.ai/workbook/${response.data.workbook.workspace_id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem ('accessToken')}`,
            },
          })
          .then((response) => {
            console.log(response.data.workbook);
            setWorkspace(response.data.workbook);
            setLoading(false);
            setForbidden(false);
          });
      })
      .catch((error) => {
        console.error('Error fetching workbook data:', error);
        setLoading(false);
        setForbidden(true);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title> Workspace </title>
      </Helmet>

      <Container maxWidth="false" style={{ width: '100%', height: '100vh', padding: 0 }}>
        {!loading &&
          workbook &&
          workspace && (
          <OpenSeaPlain fileName={params.fileName} user={user} workbook={workbook} listWorkbook={workspace} />
        )}
      </Container>
    </>
  );
}
