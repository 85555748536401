import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
// @mui
import { Card, Link, Typography, Stack, Menu, MenuItem, IconButton, Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  cursor: 'pointer',
});

// ----------------------------------------------------------------------

FolderCard.propTypes = {
  items: PropTypes.object,
};

export default function FolderCard({ items, onDelete, onEdit }) {
  const navigate = useNavigate();

  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleEditClick = () => {
    // Handle edit action here
    onEdit(items);
    handleMenuClose();
  };

  const handleOpenClick = () => {
    // Handle edit action here
    navigate(`/dashboard/image/${items._id}`);
    handleMenuClose();
  };

  const handleDeleteClick = () => {
    // Handle delete action here
   
      axios.delete(`http://viume-api.yarsi.ai/image/${items._id}`
        , { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      ).then((response) => {
        onDelete();
      }).catch((error) => {
        console.log(error);
      })
 
    handleMenuClose();
  };

  return (
    <Card>
      <IconButton
        aria-label="options"
        aria-controls="product-menu"
        aria-haspopup="true"
        onClick={handleMenuOpen}
        sx={{ position: 'absolute', top: 8, right: 8, zIndex: 10 }}
      >
        <MoreVertIcon />
      </IconButton>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <StyledProductImg onClick={handleOpenClick}  alt={items.name} src={`http://viume-api.yarsi.ai/thumbnail/${items._id}`} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link color="inherit" underline="hover">
          <Typography variant="subtitle2" noWrap>
            {items.name}
          </Typography>
        </Link>
      </Stack>

      <Menu id="product-menu" anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={handleMenuClose}>
        <MenuItem onClick={handleOpenClick}>Open</MenuItem>
        <MenuItem onClick={handleEditClick}>Edit</MenuItem>
        <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
      </Menu>
    </Card>
  );
}
