import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import FolderCard from './FolderCard';

// ----------------------------------------------------------------------

FolderList.propTypes = {
  items: PropTypes.array.isRequired,
};

export default function FolderList({ items, onDelete, onEdit, ...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {items.map((item) => (
        <Grid key={item._id} item xs={12} sm={6} md={3}>
          <FolderCard items={item} onDelete={onDelete} onEdit={onEdit} />
        </Grid>
      ))}
    </Grid>
  );
}
