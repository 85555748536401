import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Stack, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { MuiOtpInput } from 'mui-one-time-password-input';
import Iconify from '../../../components/iconify';
import { useAuth } from '../../../context/AuthContext';

export default function RegisterForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const { register, isAuthenticated } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [showOTPButton, setShowOTPButton] = useState(false);

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSuccess, setOtpSuccess] = useState(false);
  const [otpError, setOtpError] = useState('');
  const [tokenRegister, setTokenRegister] = useState('');

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (value) => {
    if (!value) {
      setEmailError('Email is required');
    } else if (!/\S+@\S+\.\S+/.test(value)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };

  const validatePassword = (value) => {
    if (!value) {
      setPasswordError('Password is required');
    } else {
      setPasswordError('');
    }
  };

  const validateFirstName = (value) => {
    if (!value) {
      setFirstNameError('First name is required');
    } else {
      setFirstNameError('');
    }
  };

  const validateLastName = (value) => {
    if (!value) {
      setLastNameError('Last name is required');
    } else {
      setLastNameError('');
    }
  };

  const validateUsername = (value) => {
    if (!value) {
      setUsernameError('Username is required');
    } else {
      setUsernameError('');
    }
  };

  const handleClick = async () => {
    validateEmail(email);
    validatePassword(password);
    validateFirstName(firstName);
    validateLastName(lastName);
    validateUsername(username);

    if (!emailError && !passwordError && !firstNameError && !lastNameError && !usernameError) {
      try {
        const header = {
          headers: {
            Authorization: `Bearer ${tokenRegister}`,
          },
        }
        const response = await axios.post(
          'http://viume-api.yarsi.ai/register',
          {
            email,
            password,
            first_name: firstName,
            last_name: lastName,
            username,
          },
          header
          
        );

        navigate('/login', { replace: true });
      } catch (error) {
        console.error('Register failed:', error.response.data.message);
      }
    }
  };

  const sendOtp = async () => {
    
    axios
      .post('http://viume-api.yarsi.ai/otp', {
        email,
      })
      .then(
        (response) => {
          setShowOTPButton(true);
        },
        (error) => {
          setEmailError("Email Already Exists")
        }
      );
  };

  const verifyOtp = async () => {
    axios
      .post('http://viume-api.yarsi.ai/otp/verify', {
        email,
        otp,
      })
      .then(
        (response) => {
          setOtpSuccess(true);
          setTokenRegister(response.data.token);
        },
        (error) => {
          setOtpError(error.response.data.message);
          setOtpSuccess(false);
        }
      );
  };

  return (
    <>
      <Stack spacing={2}>
        <TextField
          name="email"
          type="email"
          label="Email address"
          onChange={(e) => {
            setEmail(e.target.value);
            validateEmail(e.target.value);
          }}
          required
          error={Boolean(emailError)}
          helperText={emailError}
          disabled={showOTPButton}
        />

        {/* text to verify email */}
        {!showOTPButton && !otpSuccess && (
          <LoadingButton sx={{ my: 3 }} size="large" type="submit" variant="contained" onClick={sendOtp}>
            Verify Email
          </LoadingButton>
        )}

        {showOTPButton && !otpSuccess && (
          <Stack spacing={2}>
            <Typography variant="subtitle2" gutterBottom>
              Enter OTP sent to your email
            </Typography>

            <MuiOtpInput value={otp} onChange={setOtp} autoFocus length={6} separator={<span>-</span>} />
            {otpError && (
              <Typography variant="subtitle2" gutterBottom color="red">
                {otpError}
              </Typography>
            )}

            <LoadingButton sx={{ my: 3 }} size="large" type="submit" variant="contained" onClick={verifyOtp}>
              Verify
            </LoadingButton>
          </Stack>
        )}

        {otpSuccess && (
          <Stack spacing={2}>
            <TextField
              name="username"
              label="Username"
              onChange={(e) => {
                setUsername(e.target.value);
                validateUsername(e.target.value);
              }}
              required
              error={Boolean(usernameError)}
              helperText={usernameError}
            />

            <TextField
              name="firstName"
              label="First name"
              onChange={(e) => {
                setFirstName(e.target.value);
                validateFirstName(e.target.value);
              }}
              required
              error={Boolean(firstNameError)}
              helperText={firstNameError}
            />

            <TextField
              name="lastName"
              label="Last name"
              onChange={(e) => {
                setLastName(e.target.value);
                validateLastName(e.target.value);
              }}
              required
              error={Boolean(lastNameError)}
              helperText={lastNameError}
            />
            <TextField
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              onChange={(e) => {
                setPassword(e.target.value);
                validatePassword(e.target.value);
              }}
              required
              error={Boolean(passwordError)}
              helperText={passwordError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <Iconify icon="mdi:eye-off-outline" /> : <Iconify icon="mdi:eye-outline" />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <LoadingButton
              sx={{ my: 3 }}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={handleClick}
            >
              Register
            </LoadingButton>
          </Stack>
        )}
      </Stack>
    </>
  );
}
