import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Chip, TextField } from '@mui/material';
import { first, set } from 'lodash';

export default function CreateWorkbookDialog({ isOpen, onClose, onCreate }) {
  const [name, setName] = useState('');
  const [collaborators, setCollaborators] = useState([]);
  const [newCollaborator, setNewCollaborator] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [nameError, setNameError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const validateName = (value) => {
    if (!value) {
      setNameError('Name is required');
    } else {
      setNameError('');
    }
  };

  const validateFirstName = (value) => {
    if (!value) {
      setFirstNameError('First Name is required');
    } else {
      setFirstNameError('');
    }
  };

  const validateLastName = (value) => {
    if (!value) {
      setLastNameError('Last Name is required');
    } else {
      setLastNameError('');
    }
  };

  const validateEmail = (value) => {
    if (!value) {
      setEmailError('Email is required');
    } else if(!isValidEmail(value)) {
        setEmailError('Email is not valid');
    }
    else {
      setEmailError('');
    }
  };

    const validatePassword = (value) => {
        if (!value) {
            setPasswordError('Password is required');
        } else {
            setPasswordError('');
        }
    }

  const handleCreate = () => {
    if (!nameError) {
      onCreate(name, collaborators, firstName, lastName, email, password);
      handleClose();
    }
  };

  const handleAddCollaborator = () => {
    if (newCollaborator.trim() !== '') {
      if (collaborators.length < 3) {
        setCollaborators([...collaborators, newCollaborator.trim()]);
        setNewCollaborator('');
      }
    }
  };

  const handleNewCollaboratorChange = (e) => {
    setNewCollaborator(e.target.value);

    if (e.target.value.trim() !== '' && /\s|,$/.test(e.target.value)) {
      handleAddCollaborator();
    }
  };

  const handleRemoveCollaborator = (index) => {
    const newCollaborators = collaborators.filter((_, i) => i !== index);
    setCollaborators(newCollaborators);
  };
  const handleNewCollaboratorBlur = () => {
    handleAddCollaborator();
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleClose = () => {
    setName('');
    setNameError('');
    setCollaborators([]);
    setNewCollaborator('');
    setFirstName('');
    setLastName('');
    setEmail('');
    setPassword('');
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Add User</DialogTitle>
      <DialogContent>
        <TextField
          name="First Name"
          label="First Name"
          fullWidth
          onChange={(e) => {
            setFirstName(e.target.value);
            validateFirstName(e.target.value);
          }}
          required
          error={Boolean(firstNameError)}
          helperText={firstNameError}
          sx={{ mt: 1 }}
        />
        <TextField
          name="Last Name"
          label="Last Name"
          fullWidth
          onChange={(e) => {
            setLastName(e.target.value);
            validateLastName(e.target.value);
          }}
          required
          error={Boolean(lastNameError)}
          helperText={lastNameError}
          sx={{ mt: 1 }}
        />
        <TextField
          name="Username"
          label="Username"
          fullWidth
          onChange={(e) => {
            setName(e.target.value);
            validateName(e.target.value);
          }}
          required
          error={Boolean(nameError)}
          helperText={nameError}
          sx={{ mt: 1 }}
        />
        <TextField
          name="Email"
          label="Email"
          fullWidth
          onChange={(e) => {
            setEmail(e.target.value);
            validateEmail(e.target.value);
          }}
          required
          error={Boolean(emailError)}
          helperText={emailError}
          sx={{ mt: 1 }}
        />
        <TextField
            name="Password"
            label="Password"
            fullWidth
            onChange={(e) => {
                setPassword(e.target.value);
                validatePassword(e.target.value);
            }}
            required
            error={Boolean(passwordError)}
            helperText={passwordError}
            sx={{ mt: 1 }}
        />
        
        <div>
          {collaborators.map((collaborator, index) => (
            <Chip key={index} label={collaborator} onDelete={() => handleRemoveCollaborator(index)} sx={{ m: 1 }} />
          ))}
        </div>
        <TextField
          sx={{ mt: 2 }}
          label="Role"
          fullWidth
          value={newCollaborator}
          onChange={handleNewCollaboratorChange}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleAddCollaborator();
            }
          }}
          onBlur={handleNewCollaboratorBlur}
          disabled={collaborators.length >= 3}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleCreate} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
