import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';

const OpenSeadragonViewer = ({ id, user, workbook, listWorkbook }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    console.log(workbook);
    /* 
    create object from array list workbook to be like this
    const info = {
      id,
      xmlUrl: `http://viume-api.yarsi.ai/xmltiles/${id}`,
      tileUrl: `http://viume-api.yarsi.ai/tiles/${id}/`,
    }
    */
    const listImage = listWorkbook.map((item) => ({
      id: item._id,
      xmlUrl: `http://viume-api.yarsi.ai/xmltiles/${item._id}`,
      tileUrl: `http://viume-api.yarsi.ai/tiles/${item._id}/`,
      zoomLevel: item.zoomLevel,
      workbook: item,
    }));

    // Create a separate object for the workbook item
    const workbookObject = {
      id: workbook._id,
      xmlUrl: `http://viume-api.yarsi.ai/xmltiles/${workbook._id}`,
      tileUrl: `http://viume-api.yarsi.ai/tiles/${workbook._id}/`,
      zoomLevel: workbook.zoomLevel,
      workbook,
    };

    const filteredListImage = listImage.filter((item) => item.id !== workbookObject.id);

    // Concatenate the workbookObject with the rest of the listImage items
    const orderedListImage = [workbookObject, ...filteredListImage];

    const iframe = iframeRef.current;
    const info = {
      data: orderedListImage,
      user,
      currentWorkbook: workbook,
    };
    console.log(info);
    if (iframe) {
      const handleIframeLoad = () => {
        iframe.contentWindow.postMessage({ info }, '*');
      };

      // Attach load event listener
      iframe.addEventListener('load', handleIframeLoad);

      // Remove the event listener when component unmounts
      return () => {
        iframe.removeEventListener('load', handleIframeLoad);
      };
    }

    // Return undefined to satisfy the consistent-return rule
    return undefined;
  }, [id]);

  return (
    <div style={{ border: 'none' }}>
      {' '}
      <iframe
        title="openseadragon-viewer"
        ref={iframeRef}
        src={`/openseadragon-full.html?id=1`}
        style={{ width: '100%', height: '100vh', border: 'none' }}
      />
    </div>
  );
};

export default OpenSeadragonViewer;