import React, {useRef, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Chip,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Alert,
} from '@mui/material';
import "./DialogUpload.css";

export default function CreateWorkbookDialog({ isOpen, onClose, onUpload }) {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [imageFile, setImageFile] = useState('');
  const [collaborators, setCollaborators] = useState([]);
  const [newCollaborator, setNewCollaborator] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1); 
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("select");
  const [fileSize, setFileSize] = useState(false);
  const inputRef = useRef();
  

  const clearFileInput = () => {
    inputRef.current.value = "";
    setImageFile(null);
    setProgress(0);
    setUploadStatus("select");
  };

  
  const zoomLevels = [1, 5, 10, 20];

  const handleZoomLevelChange = (event) => {
    setZoomLevel(event.target.value);
  };

  const validateName = (value) => {
    if (!value) {
      setNameError('Name is required');
    } else {
      setNameError('');
    }
  };
  

  const handleUpload = () => {
    if (!nameError && name && imageFile) {

      onUpload(name, imageFile, collaborators, isPublic, zoomLevel);
      onClose();
    } else {
      setNameError('Name is required');
    }
  };

  const handleImageUpload = (e) => {
    setFileSize(false);
    const file = e.target.files[0]; // Get the uploaded file
  // 500MB
    console.log(`fileSize ${file.size}`)
    if (file.size >= 300000000) {
      setFileSize(true);
      console.log(`fileSize terlalu besar`)
    } else{
      
      setImageFile(file);     }
   // Store the file in state
  };

  const handleAddCollaborator = () => {
    if (newCollaborator.trim() !== '') {
      if (isValidEmail(newCollaborator) && collaborators.length < 3) {
        setCollaborators([...collaborators, newCollaborator.trim()]);
        setNewCollaborator('');
      }
    }
  };

  const handleNewCollaboratorChange = (e) => {
    setNewCollaborator(e.target.value);

    if (e.target.value.trim() !== '' && /\s|,$/.test(e.target.value)) {
      handleAddCollaborator();
    }
  };

  const handleRemoveCollaborator = (index) => {
    const newCollaborators = collaborators.filter((_, i) => i !== index);
    setCollaborators(newCollaborators);
  };
  const handleNewCollaboratorBlur = () => {
    handleAddCollaborator();
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleClose = () => {
    setFileSize(false)
    setName('');
    setNameError('');
    setImageFile('');
    setCollaborators([]);
    setNewCollaborator('');
    setIsPublic(false);
    setZoomLevel(1);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Add File to Workbook</DialogTitle>
      <DialogContent>
        <TextField
          name="name"
          label="Name"
          fullWidth
          onChange={(e) => {
            validateName(e.target.value);
            setName(e.target.value);
          }}
          required
          error={Boolean(nameError)}
          helperText={nameError}
          sx={{ mt: 1 }}
        />

        <div>
          {collaborators.map((collaborator, index) => (
            <Chip key={index} label={collaborator} onDelete={() => handleRemoveCollaborator(index)} sx={{ m: 1 }} />
          ))}
        </div>
        <TextField
          sx={{ mt: 2 }}
          label="Add Collaborator"
          fullWidth
          value={newCollaborator}
          onChange={handleNewCollaboratorChange}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleAddCollaborator();
            }
          }}
          onBlur={handleNewCollaboratorBlur}
          disabled={collaborators.length >= 3} // Disable the TextField when the limit is reached
        />
        <label htmlFor="image-upload-input">
          <input
            type="file"
            accept=".png, .jpg, .jpeg, .tif, .tiff, .svs"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
            id="image-upload-input"
          />
          <Button fullWidth variant="outlined" component="span" sx={{ mt: 2 }}>
            Upload Image
          </Button>
        </label>

        {fileSize &&(
          <>
          <Alert severity="error"  sx={{ mt: 1, mb: 2 }}>You file more than 300 MB</Alert>
          </>
         
        )}
        {imageFile && (
          <>
          <Typography variant="caption" sx={{ margin: '0 auto', display: 'block' }}>
              "{imageFile.name} ({(imageFile.size / (1024*1024)).toFixed(2)} MB)" 
            </Typography>

            {/* <Alert severity="error">File Size to Big</Alert> */}
          </>
          
        )}
        <div>
          <Typography variant="subtitle2" sx={{ mt: 1 }}>
            Image Zoom Level:
          </Typography>
          <Select value={zoomLevel} onChange={handleZoomLevelChange} fullWidth sx={{ mt: 1 }}>
            {zoomLevels.map((level) => (
              <MenuItem key={level} value={level}>
              {`${level}x`}
            </MenuItem>
            ))}
          </Select>
        </div>

        <FormControlLabel
          control={
            <Checkbox
              checked={isPublic}
              onChange={(event) => setIsPublic(event.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Public Access"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleUpload} disabled = {fileSize} color="primary">
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
