import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { Container, Stack, Typography, Button, Box, Tabs, Tab, CircularProgress } from '@mui/material';
import axios from 'axios';

// components
import { WorkspaceList } from '../sections/@dashboard/workspace';
import DialogWorkbook from '../components/dialog/DialogWorkbook';
import DialogEditWorkbook from '../components/dialog/DialodEditWorkbook';
// mock
import PRODUCTS from '../_mock/workspace';

// ----------------------------------------------------------------------

export default function HomePage() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const [currentWorkbook, setCurrentWorkbook] = useState(null);
  const [currentTab, setCurrentTab] = useState('my');
  const [loading, setLoading] = useState(false);
  const [workspace, setWorkspace] = useState([]);
  const [updated, setUpdated] = useState(false);

  const handleOpenEditDialog = () => {
    setIsEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setCurrentWorkbook(null);
    setIsEditDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleDelete = () => {
    setUpdated(!updated);
  };

  const handleEdit = (data) => {
    setCurrentWorkbook(data);
    handleOpenEditDialog();
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    setLoading(true);
    if (newValue === 'my') {
      axios
        .get('http://viume-api.yarsi.ai/workspace', {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        })
        .then((response) => {
          setWorkspace(response.data.workspace);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching user data:', error.response?.data?.message || 'Unknown error');
          setLoading(false);
        });
    } else {
      axios
        .get('http://viume-api.yarsi.ai/workspace/shared', {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        })
        .then((response) => {
          setWorkspace(response.data.workspace);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching user data:', error.response?.data?.message || 'Unknown error');
          setLoading(false);
        });
    }
  };

  const handleEditWorkbook = (id, name, collaborator, description = '') => {
    axios
      .put(
        `http://viume-api.yarsi.ai/workspace`,
        { id, name, collaborator, description },
        { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      )
      .then((response) => {
        setUpdated(!updated);
      })
      .catch((error) => {
        console.error('Error fetching user data:', error.response?.data?.message || 'Unknown error');
      });
  };

  const handleCreateWorkbook = (name, collaborator, description = '') => {
    axios
      .post(
        'http://viume-api.yarsi.ai/workspace',
        { name, collaborator, description },
        { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      )
      .then((response) => {
        setUpdated(!updated);
      });
  };

  useEffect(() => {
    setLoading(true);
      axios
        .get('http://viume-api.yarsi.ai/workspace', {
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        })
        .then((response) => {
          setWorkspace(response.data.workspace);
          setLoading(false);
        }).catch((error) => {
          console.error('Error fetching user data:', error.response?.data?.message || 'Unknown error');
          setLoading(false);
        });
    
  }, [updated]);

  return (
    <>
      <Helmet>
        <title> Workspace</title>
      </Helmet>

      <Container maxWidth={'xl'}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Workspace
        </Typography>

        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 5 }}
        >
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <Tabs value={currentTab} onChange={handleTabChange} aria-label="Tab">
              <Tab label="My Folder" value="my" />
              <Tab label="Shared Folder" value="shared" />
            </Tabs>
          </Stack>

          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <Button onClick={handleOpenDialog} variant="contained">
              Add Workbook
            </Button>
          </Stack>
        </Stack>

        {workspace.length > 0 ? (
          loading ? (
            <Box textAlign="center" py={5}>
              <CircularProgress />
            </Box>
          ) : (
            <WorkspaceList items={workspace} onDelete={handleDelete} onEdit={handleEdit} currentTab={currentTab} />
          )
        ) : (
          <Box textAlign="center" py={5}>
            <img
              src={'/assets/illustrations/workspace_kosong.png'}
              alt="No Data"
              style={{ maxWidth: '100%', margin: '0 auto' }}
            />
          </Box>
        )}

        <DialogWorkbook isOpen={isDialogOpen} onClose={handleCloseDialog} onCreate={handleCreateWorkbook} />

        {currentWorkbook && (
          <DialogEditWorkbook
            isOpen={isEditDialogOpen}
            onClose={handleCloseEditDialog}
            onCreate={handleEditWorkbook}
            currentWorkbook={currentWorkbook}
          />
        )}
      </Container>
    </>
  );
}
