import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
// @mui
import ChatIcon from '@mui/icons-material/Chat';
import { Container, Stack, Typography, Button, Fab, Tooltip, Box, CircularProgress } from '@mui/material';
// components
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';
// mock
import PRODUCTS from '../_mock/workspace';
import OpenSeaPlain from '../components/deepzoom/OpenSeaDragonPlain';
import { useAuth } from '../context/AuthContext';

// ----------------------------------------------------------------------

export default function ProductsPage() {
  const params = useParams();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [loadingPredict, setLoadingPredict] = useState(false);
  const [forbidden, setForbidden] = useState(false);
  const [workbook, setWorkbook] = useState(null);
  const [workspace, setWorkspace] = useState(null);
  const [isPredict, setIsPredict] = useState(false);
  const [error, setError] = useState(null); // State untuk error


  const handlePredictBC = async () => {
    setIsPredict(true);
    try {
      console.log("masuk try");
      setLoadingPredict(true);
      const response = await axios.post(
        `https://kj0nglhn-8001.asse.devtunnels.ms/process_images/${params.fileName}`
      );
      Swal.fire({
        title: "Predicting in Progress ",
        text: "Process in Background",
        icon: "info"
      });
      console.log(response.data.message);
    } catch (error) {
      console.log("masuk try");
      console.error('Error processing images:', error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      setError(error.message);
    } finally {
      console.log("masuk try");
      setLoadingPredict(false);
      setIsPredict(false);
    }
  };

  const handlePredictMC = async () => {
    setIsPredict(true);
    console.log("masuk handlePredictMC");
    try {
      setIsPredict(true);
      setLoadingPredict(true);
      const response = await axios.post(
        `https://kj0nglhn-8001.asse.devtunnels.ms/process_images/${params.fileName}`
      );
      console.log("masuk try");
      console.log(response.data.message);
      Swal.fire({
        title: "Predicting in Progress ",
        text: "Process in Background",
        icon: "info"
      });
    } catch (error) {
      console.log("masuk close");
      console.error('Error processing images:', error);
      setError(error.message);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!", 
      });
    } finally {
      console.log("masuk finally");
      setIsPredict(false);
      setLoadingPredict(false);
    }
  
  };

 
  useEffect(() => {
    setLoading(true);
    const headers = {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
    };

    axios
      .get(`http://viume-api.yarsi.ai/image/${params.fileName}`, headers)
      .then((response) => {
        setWorkbook(response.data.workbook);

        axios
          .get(`http://viume-api.yarsi.ai/workbook/${response.data.workbook.workspace_id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then((response) => {
            console.log(response.data.workbook)
            setWorkspace(response.data.workbook);
            setLoading(false);
            setForbidden(false);
          })
      })
      .catch((error) => {
        console.error('Error fetching workbook data:', error);
        setLoading(false);
        setForbidden(true);
      });
  }, []);

  if (loading) {
    return (
      <Container maxWidth="xl">
        <Box textAlign="center" py={5}>
          <CircularProgress />
          <Typography variant="caption" sx={{ margin: '0 auto', display: 'block' }}>
            ...
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title> Workspace </title>
      </Helmet>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Image Viewer
        </Typography>
        {/* <Stack direction="row" spacing={1} flexShrink={0} sx={{ mr: 2 }}>
         
        </Stack> */}
        {loadingPredict && (
          <Fab variant="extended">
           <CircularProgress sx={{ mr: 1 }} />
               Predicting
              </Fab>
        )}
        {forbidden ? (
          <Box textAlign="center" py={5}>
            <img
              src={'/assets/illustrations/workspace_forbidden.png'}
              alt="No Data"
              style={{ maxWidth: '100%', margin: '0 auto' }}
            />
          </Box>
        ) : (
          !loading &&
          workbook &&
          workspace && (
            <OpenSeaPlain id={params.fileName} user={user} workbook={workbook} listWorkbook={workspace} />
          )
        )}
        <Fab variant="extended" size="large" color="primary" onClick={handlePredictBC} disabled = {isPredict}>
        AI Predict Binary Class
        </Fab>

        <Fab variant="extended" size="large" color="primary" onClick={handlePredictMC} sx ={{ml:1}} disabled = {isPredict}>
        AI Predict Multi Class
        </Fab>
      </Container>
    </>
  );
}
