import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

import { Container, Stack, Typography, Button, Box, Chip, Fab } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const login = async (email, password) => {
    try {
      const response = await axios.post('http://viume-api.yarsi.ai/login', { email, password });
      setIsLoading(true)
      const accessToken = response.data.token;
      localStorage.setItem('accessToken', accessToken);
      await fetchUserData();
      setIsLoading(false)
    } catch (error) {
      console.error('Login failed:', error.response.data.message);
    }
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    setUser(null);
    setIsAuthenticated(false); // Ensure consistent state
  };

  const checkAuth = async () => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      await fetchUserData();
    } else {
      setUser(null);
      setIsAuthenticated(false);
    }
  };

  const fetchUserData = async () => {
    try {
      const header = {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      };
      const response = await axios.get('http://viume-api.yarsi.ai/user', { headers: header });

      setUser(response.data.user);
      setIsAuthenticated(true);
    } catch (error) {
      setUser(null);
      localStorage.removeItem('accessToken');
      console.error('Error fetching user data:', error.response?.data?.message || 'Unknown error');
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
   
  }, []);

  if(isLoading){
    return(
      <Container maxWidth="xl">
        <Box textAlign="center" py={5}>
          <CircularProgress />
          <Typography variant="caption" sx={{ margin: '0 auto', display: 'block' }}>
                ...
              </Typography>
        </Box>
      </Container>
    );
  }

  return !isLoading && (
      <AuthContext.Provider value={{ user, login, logout, isAuthenticated, checkAuth }}>
      {children}
    </AuthContext.Provider>
    
  );
};

export const useAuth = () => useContext(AuthContext);
